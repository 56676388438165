import { Link } from "@remix-run/react";
import React, { useState } from "react";

const PremiumNotify = (User: any) => {
  const [closed, setClosed] = useState(false);

  if (closed) return null;
  const isPremiumUser = User.user?.subscription === "premium";  // Fixed this line
  if (isPremiumUser) return null;
  return (
    <div className="relative mt-6 p-6 rounded-lg border border-primary/20 bg-gradient-to-br from-primary/10 via-primary/10 to-secondary/10">
      <div className="absolute right-0 top-0 h-24 w-24 translate-x-8 -translate-y-8 overflow-hidden">
        <div className="absolute transform rotate-45 bg-purple-500/10 h-4 w-[120%]" />
      </div>

      <button
        onClick={() => setClosed(true)}
        className="absolute right-2 top-2 h-8 w-8 rounded-full flex items-center justify-center hover:bg-purple-500/10 transition-colors"
        aria-label="Close notification"
      >
        <i className="fas fa-times text-primary text-sm" />
      </button>

      <div className="relative">
        {/* Header */}
        <div className="flex items-center gap-2">
          <i className="fas fa-crown text-primary" />
          <h4 className="font-semibold text-primary">
            Unlock Premium Features
          </h4>
        </div>

        <p className="mt-2 text-sm text-gray-600 max-w-[90%]">
          Take your experience to the next level with premium features. One-time
          payment of $5 for lifetime access.
        </p>

        <Link
          to="/purchase"
          className="mt-4 inline-flex items-center gap-2 px-4 py-2 rounded-md bg-primary text-white hover:bg-primary transition-colors group"
        >
          <span>Upgrade Now</span>
          <i className="fas fa-arrow-right transform transition-transform group-hover:translate-x-1" />
        </Link>
      </div>
    </div>
  );
};

export default PremiumNotify;
